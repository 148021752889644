import { GoogleMap, Marker } from "vue3-google-map"
import VueEasyLightbox from "vue-easy-lightbox"
import Layout from "@/views/layouts/main.vue"
import { todoGetters } from "@/state/helpers"

import markerIcon  from '@/assets/img/location24x36.png'
import ContratoSrv from '@/services/ContratoSrv.js'
import ClienteSrv from '@/services/ClienteSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'

// Archivos de routes
import ProductoSrv from '@/services/ProductoSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
import SitioSrv from '@/services/SitioSrv.js'

// Archivos de cpes
import EstadoCpesSrv from '@/services/EstadoCpesSrv.js'
import ModeloCpesSrv from '@/services/ModeloCpesSrv.js'
import MarcaCpesSrv from '@/services/MarcaCpesSrv.js'
import CpeSrv from '@/services/CpeSrv.js'

// Archivos de dispositivos de hogar
import DhSrv from '@/services/DhSrv.js'
import ModeloDhsSrv from '@/services/ModeloDhsSrv.js'
import EstadoDhsSrv from '@/services/EstadoDhsSrv.js'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'
import clienteIcon from '@/assets/img/usuario.png'
import TipoDhsSrv from '@/services/TipoDhsSrv.js'

import Datepicker from 'vue3-datepicker'
var ipaddr = require('ipaddr.js')
import Swal from 'sweetalert2'
import moment from 'moment'
import API from '@/API.js'
import md5 from 'md5'

export default {
  name: 'EdicionCliente',
  components: {
    Layout,
    VueEasyLightbox,
    Datepicker,
    GoogleMap,
    Marker
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem("argusblack.token"),
      mostrar_clave: false,
      cliente: {
        id: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        rfc: '',
        sexo: 'hombre',
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: 0,
        estado_residencia: '',
        pais: '',
        nota: '',
        empresa: 0,
        clave: '',
        clave_sin_encriptar: '',
        activo: 1,
        correo: '',
        numero: '',
        prefnumcli: '',
        telefonos: [
          // {
          //     prefijo: '',
          //     numero: '',
          //     tipo: ''
          // }, ...
        ],
        messenger: '',
        url1: '',
        url2: '',
        foto: null,
        datoaux1: '',
        datoaux2: '',
        datoaux3: '',
        datoaux4: '',
        tiene_foto: 0
      },      
      idCliente: this.$route.params.id_cliente,
      correo: '',
      numeroTelefono: '',
      tipoTelefono: 'celular',
      prefijo: null,
      modo: 'nuevo',
      paises: [],
      bandera_spinner: false,
      bandera_nuevo_contrato: false,
      
      markerIcon:markerIcon,
      mapaZoom: 12,
      posicionInicial: {
        lat: 0,
        lng: 0
      },
      lat_lng: 0,
      bloquear_lat_lng: true,
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],
      contrato: {
        id: null,
        id_cliente: null,
        dia_pago: 0,
        n_dias_corte: 0,
        n_dias_generar_factura: 1,
        hora_corte: '12:00:00',
        fecha_instalacion: null,
        fecha_vencimiento: null,
        nota: '',
        nota_cancelacion: '',
        latitud: 0,
        longitud: 0,
        cargo: 0,
        descuento: 0,
        prepago: 1,
        id_tipo_renta: 1, //1 corresponde a la mensual fija y 2 a mensual variable
        dia_inicio_periodo: 1,
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: '',
        estado_residencia: '',
        pais: '',
        id_cpe: null,
        id_router: null,
        id_isp: null,
        id_servicio: null,
        id_dh: null,
        carga_descarga_servicio: '',
        precio_servicio: 0,
        id_contrato_fisico: '',
        inicio_periodo: null,
        fin_periodo: null,
        cargo_morosidad: false,
        notificacion_correo: false, //notificacion por enviar
        control_manual_cpe: false,
        enlace_activo_cpe: true,
        servicios_complementarios: [],
        id_sitio: null,
        modo_generar_facturas: 1
      },
      servicios: [],
      sitios: [],
      isps: [],
      cpes: [],
      routers: [],
      estados_cpes: [],
      marcas_cpes: [],
      modelos_cpes:[],
      dhs: [],
      estados_dh:[],
      modelos_dh:[],
      tipos_dh:[],
      marcas_dh:[],
      actualizarCpe: false,
      cpeSeleccionado: {
        id: null,
        ip_wan: '',
        id_router: null,
        id_isp: null,
        mac_wan: '',
        pppoe: 0,
        id_estado: null,
        id_marca: null,
        id_modelo: null,
        id_sitio: null,
        id_servicio: null,
        id_perfil_velocidad: null,
        control_manual: false,
        enlace_activo: false,
        nombre:'',
        marca: {
          nombre: ''
        },
        modelo: {
          identificador: ''
        }
      },
      actualizarDh: false,
      dhSeleccionado: {
        id: null,
        ip_wan: '',
        mac_wan: '',
        id_estado: null,
        id_marca: null,
        id_modelo: null,
        id_tipo: null,
        marca: {
          nombre: ''
        },
        modelo: {
          identificador: ''
        },
        tipo: {
          nombre: ''
        }
      },
      servicioSeleccionado: {
        nombre: ''
      },
      serviciosPPPoE: [],
      puertas_enlace: '',
      /**
       * configuracionCpe es aquella sugerida al usuario administrador, para que pueda
       * configurar su CPE físicamente
       */
      configuracionCpe: {
        puerta_enlace: '',
        mascara_subred: '',
        cidr: null,
        dns1: '',
        dns2: ''
      },
      clienteIcon: clienteIcon,
      foto_visor: null,
      foto: null,
      foto_cliente: false,
      visibleImagen: false
    }
  },

  created() {
    var self = this

    // Carga del modo
    if (this.$route.path.indexOf('nuevo') == -1) this.modo = 'edicion'

    // Carga del cliente
    if (this.modo == 'edicion') self.cargarCliente()

    self.cargarPaises()
    self.cargarPrefijoTelefonico()
    self.cargarFotoCliente()
    // self.cargaInicialNuevoContrato()
  },

  computed: {
    ...todoGetters,
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    fecha_pago() {
      return moment(this.contrato.fin_periodo, 'DD/MM/YYYY').add(this.contrato.dia_pago, 'days').format('DD/MM/YYYY')
    },
    fecha_corte_servicio() {
      return moment(this.fecha_pago, 'DD/MM/YYYY').add(this.contrato.n_dias_corte, 'days').format('DD/MM/YYYY')
    },
    fecha_generar_nueva_factura() {
      if(this.contrato.id_tipo_renta == 1)
        return moment(this.fecha_pago, 'DD/MM/YYYY').subtract(this.contrato.n_dias_generar_factura, 'days').format('DD/MM/YYYY')
      else
        return moment(this.contrato.fin_periodo, 'DD/MM/YYYY').subtract(this.contrato.n_dias_generar_factura, 'days').format('DD/MM/YYYY')
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },

  watch: {
    'contrato.inicio_periodo': function(newVal, oldVal) {
      var self = this 
      self.contrato.fin_periodo = new Date(moment(newVal, 'DD/MM/YYYY').add(1, 'month').format())
    },

    'contrato.control_manual_cpe': function(newCal,oldVal){
      var self = this
      self.actualizarCpe = true
    },

    'contrato.enlace_activo_cpe': function(newVal,oldVal){
      var self = this
      self.actualizarCpe = true
    },
    bandera_nuevo_contrato: function(newVal,oldVal){
      var self = this
      if(newVal == true) self.cargaInicialNuevoContrato()
    }
  },

  methods: {
    actualizar: function() {
      var self = this

      var cliente = self.cliente

      self.bandera_spinner = true

      if(cliente.numero == null || cliente.numero == ''){
        iu.msg.warning('Es necesario el número de cliente')
        self.$refs.numero_cliente.select()
        self.bandera_spinner = false
        return
      }

      if(cliente.clave_sin_encriptar != null && cliente.clave_sin_encriptar != ''){
        let clave_sin_encriptar = self.cliente.clave_sin_encriptar
        let clave_encriptada = md5(clave_sin_encriptar)

        self.cliente.clave = clave_encriptada
        self.cliente.clave_sin_encriptar = clave_sin_encriptar
        cliente.clave = clave_encriptada
        cliente.clave_sin_encriptar = clave_sin_encriptar
      }

      if(cliente.nombre == ''){
        iu.msg.warning('Es necesario un nombre para el cliente')
        self.$refs.nombre_cliente.select()
        self.bandera_spinner = false
        return
      }

      if(cliente.apellido_paterno == ''){
        iu.msg.warning('Es necesario un apellido paterno para el cliente')
        self.$refs.apellido_paterno.select()
        self.bandera_spinner = false
        return
      }
      
      ClienteSrv.actualizar(cliente).then(response => {
        iu.msg.success('Cliente actualizado')
        self.refrescarTelefonos()
        self.cargarCliente()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el cliente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    actualizarPrecioServicio: function() {
      var self = this
      if (self.contrato.id_servicio == null) return

      let index = this.servicios.findIndex(function(srv) {
        return srv.id == self.contrato.id_servicio
      })

      let servicio = self.servicios[index]

      this.contrato.precio_servicio = servicio.precio
      this.contrato.precio_servicio = parseFloat(
        this.contrato.precio_servicio
      ).toFixed(2)
    },

    actualizarServicioSeleccionado: function() {
      var self = this

      var index = self.servicios.findIndex(function(srv) {
        return srv.id == self.contrato.id_servicio
      })

      if (index >= 0) {
        // En este punto, si se encontró el indice del servicio
        // a seleccionar se selecciona el servicio
        self.servicioSeleccionado = self.servicios[index]
        self.cpeSeleccionado.id_servicio = self.servicios[index].id
        self.cpeSeleccionado.id_perfil_velocidad = self.servicios[index].id_perfil_velocidad_cpes

        this.actualizarCargaDescargaContrato()
        this.actualizarPrecioServicio()
      } else if (self.contrato.id_servicio != null) {
        // Si no se encontró el servicio seleccionado, significa que
        // no está en la lista de servicios activos, debe de encontrarse
        // en la lista de servicios inactivos.
        // Se debe de realizar la carga por separado

        self.bandera_spinner = true

        ProductoSrv.planServicioJSON(self.contrato.id_servicio).then(response => {
          let servicio = response.data

          self.servicios.push(servicio)
          self.servicioSeleccionado =
            self.servicios[self.servicios.length - 1]

          self.actualizarCargaDescargaContrato()
          self.actualizarPrecioServicio()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }
    },

    actualizarCargaDescargaContrato: function() {
      var self = this

      if (self.contrato.id_servicio == null) return

      let index = this.servicios.findIndex(function(srv) {
        return srv.id == self.contrato.id_servicio
      })

      this.contrato.carga_descarga_servicio = `${
        self.servicios[index].velocidad_descarga
      }${self.servicios[index].unidad_descarga}/${
        self.servicios[index].velocidad_carga
      }${self.servicios[index].unidad_carga}`
    },

    cargaInicialNuevoContrato: function() {
      var self = this
      let params

      // Definición de promesas
      var routersProm = RouterSrv.vrouters1JSON()

      routersProm.then(response => {
        // Registro de los routers
        self.routers = response.data

        // Si no hay routers, se evita continuar
        if (self.routers.length > 0) {
          // Selección del primer router
          self.contrato.id_router = self.routers[0].id
          self.cpeSeleccionado.id_router = self.routers[0].id
          self.cargarServiciosPPPoE()
          self.cargarPuertasEnlace(function() {
            self.actualizarConfiguracionIpParaCpe()
          })

        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      var ispsProm = routersProm.then(response => {
        if(response.data.length > 0)
          return RouterSrv.ispsJSON(self.contrato.id_router)
      }).then(response => {
        if(response) self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      params = { paginacion: false, activo: true }
      var serviciosProm = ProductoSrv.planesServicioJSON(params)

      serviciosProm.then(response => {
        self.servicios = response.data

        // Selección del primer servicio
        if (self.servicios.length > 0) {
          self.contrato.id_servicio = self.servicios[0].id
          self.actualizarServicioSeleccionado()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      SistemaSrv.fecha({ formato: 'Y-m-d H:i:s' }).then(response => {
        self.contrato.fecha_instalacion = new Date(response.data)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      self.cpesDisponiblesParaContrato()
      self.dhsDisponiblesParaContrato()

      self.cargarMarcasCpes()
      self.cargarEstadosCpes()
      self.cargarSitios()

      self.cargarEstadosDH()
      self.cargarTiposDH()
      self.cargarMarcasDH()

      self.iniciarMapa()
    },

    cpesDisponiblesParaContrato:function(){
      var self = this,
      params = { con_marca: true, con_modelo: true }
      CpeSrv.disponiblesParaContratoJSON(params).then(response => {
        self.cpes = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    dhsDisponiblesParaContrato: function() {
      var self = this,
      params = { con_marca: true, con_modelo: true, con_tipo: true }
      DhSrv.disponiblesParaContratoJSON(params).then(response => {
        self.dhs = response.data

        self.dhs.forEach(function(dh) {
          dh.ocupado = false
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    eliminarFoto: function() {
      var self = this
      self.foto = null
      self.foto_visor = null
      self.cliente.eliminar_antigua_foto = 1
    },

    actualizarCpeSeleccionado: function() {
      var self = this

      self.actualizarCpe = false

      if(self.contrato.id_cpe == -1) {
        self.cpeSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          id_router: null,
          id_isp: null,
          mac_wan: '',
          pppoe: 0,
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_sitio: null,
          id_servicio: null,
          id_perfil_velocidad: null,
          enlace_activo: 1,
          control_manual: 1,
          nombre:'',
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          }
        })

        self.contrato.id_router = null
        self.contrato.id_isp = null
        self.contrato.control_manual_cpe = true
        self.contrato.enlace_activo_cpe = true
        self.bandera_spinner = false
        self.actualizarServicioSeleccionado()
        return
      }

      if (self.contrato.id_cpe == null) {
        self.cpeSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          id_router: null,
          id_isp: null,
          mac_wan: '',
          pppoe: 0,
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_sitio: null,
          id_servicio: null,
          id_perfil_velocidad: null,
          enlace_activo: 1,
          control_manual: 1,
          nombre:'',
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          }
        })

        self.contrato.id_router = null
        self.contrato.id_isp = null
        self.contrato.control_manual_cpe = true
        self.contrato.enlace_activo_cpe = true
        self.cpeSeleccionado.id_servicio = null
        self.cpeSeleccionado.id_perfil_velocidad = null
        self.bandera_spinner = false
        return
      }

      let index = self.cpes.findIndex(function(cpe) {
        return cpe.id == self.contrato.id_cpe
      })
      self.cpeSeleccionado = self.cpes[index]

      // Actualización ID del router seleccionado en el contrato
      if (self.cpeSeleccionado.id_router != null) {
        self.contrato.id_router = self.cpeSeleccionado.id_router
        self.contrato.id_isp = self.cpeSeleccionado.id_isp
        self.actualizarIspsRouter(self.contrato.id_isp)
        self.cargarServiciosPPPoE()
        self.cargarPuertasEnlace(function() {
          self.actualizarConfiguracionIpParaCpe()
        })
      }

      if( self.contrato.id_servicio != null ) self.actualizarServicioSeleccionado()

      if( self.cpeSeleccionado.id_marca != null ) self.cargarModelosCpes()
    },

    actualizarIspsRouter: function(idIspSeleccionado) {
      var self = this
      idIspSeleccionado = idIspSeleccionado || null

      self.bandera_spinner = true

      RouterSrv.ispsJSON(self.contrato.id_router).then(response => {
        self.isps = response.data

        // Selección del primer ISP
        if (idIspSeleccionado == null && self.isps.length > 0){
          self.contrato.id_isp = self.isps[0].id
          self.cpeSeleccionado.id_isp = self.isps[0].id
        }
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarDhSeleccionado: function() {
      var self = this

      self.actualizarDh = false
      // como el DH es opcional, es posible que el ID venga en NULL,
      // por lo cual se evita continuar el proceso de selección del DH
      if(self.contrato.id_dh == -1) {
        self.dhSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          mac_wan: '',
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_tipo: null,
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          },
          tipo: {
            nombre: ''
          }
        })
        self.bandera_spinner = false
        return
      }

      if (self.contrato.id_dh == null) {
        self.dhSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          mac_wan: '',
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_tipo: null,
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          },
          tipo: {
            nombre: ''
          }
        })
        self.bandera_spinner = false
        return
      }

      let index = this.dhs.findIndex(function(dh) {
        return dh.id == self.contrato.id_dh
      })
      this.dhSeleccionado = this.dhs[index]

      if(this.dhSeleccionado.id_marca != null) self.cargarModelosDH()
    },

    cargarSitios: function() {
      var self = this

      let params = { dato: self.dato }

      self.bandera_spinner = true

      SitioSrv.sitiosJSON(params).then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarTelefono() {
      var self = this

      if(self.numeroTelefono.length > 20){
        iu.msg.warning('El número telefónico no puedo contener más de 20 números.')
        self.$refs.numeroTelefonico.select().select()
        return
      }

      if(self.numeroTelefono.length == ''){
        iu.msg.warning('No se ha ingresado un número telefónico.')
        self.$refs.numeroTelefonico.select()
        return
      }

      if(self.prefijo == null || self.prefijo == '') {
         iu.msg.warning('No se ha ingresado un prefijo telefónico')
        self.$refs.prefijo.select()
        return
      }

      self.cliente.telefonos.push({
        numero: self.numeroTelefono,
        prefijo: self.prefijo && self.prefijo.substring(0, 1) == '+'? self.prefijo : '+' + self.prefijo,
        tipo: self.tipoTelefono
      })
      self.numeroTelefono = ''
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarServiciosPPPoE: function() {
      var self = this

      // Si el CPE no tiene un router definido, evita continuar
      if (self.cpeSeleccionado.id_router == null) return

      RouterSrv.serviciosPppoeJSON(self.cpeSeleccionado.id_router).then(response => {
        self.serviciosPPPoE = response.data

        if (self.cpeSeleccionado.pppoe == true && self.serviciosPPPoE.length == 0) {
          iu.msg.warning(
            'No hay un servicio PPPoE disponible en el router para el CPE. ' +
              'Selecciona otro router o registra una LAN con el servicio PPPoE activado'
          )
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los servicios PPPoE'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPuertasEnlace: function(callback) {
      var self = this

      // Si el CPE no tiene definido un id de router, evita continuar
      if (self.cpeSeleccionado.id_router == null) {
        self.puertas_enlace = []
        if (callback) callback()
        return
      }

      RouterSrv.puertasEnlaceJSON(self.cpeSeleccionado.id_router).then(response => {
        self.puertas_enlace = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las puertas de enlace del router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    actualizarConfiguracionIpParaCpe: function() {
      var self = this,
        ipcpe = self.cpeSeleccionado.ip_wan

      // Si tiene la bandera PPPoE activada se evita continuar
      if (self.cpeSeleccionado.pppoe == true) return

      // Si el CPE no tiene una dirección IP WAN registrada, se evita continuar
      if (ipcpe == null || ipcpe == '') return

      // Filtrado de la puerta de enlace compatible
      var puertaEnlace = self.puertas_enlace.find(function(pe) {
        let tmp = pe.split('/')

        // Si no tiene CIDR, evita continuar
        if (tmp[1] == undefined) return false
        let cidr = tmp[1]

        return (
          ipaddr.IPv4.networkAddressFromCIDR(pe).toString() ==
          ipaddr.IPv4.networkAddressFromCIDR(ipcpe + '/' + cidr).toString()
        )
      })

      let addr
      try {
        let tmp1 = puertaEnlace.split('/')
        addr = ipaddr.IPv4.parseCIDR(puertaEnlace)
        self.configuracionCpe.puerta_enlace = addr[0].toString()
        self.configuracionCpe.mascara_subred = ipaddr.IPv4.subnetMaskFromPrefixLength(
          addr[1]
        )
        self.configuracionCpe.cidr = tmp1[1]
      } catch (error) {
        if (self.cpeSeleccionado.id_router != null) {
          self.configuracionCpe.puerta_enlace = '- - Ninguna - -'
          self.configuracionCpe.mascara_subred = '- - Ninguna - -'
          iu.msg
            .warning(`El router seleccionado no cuenta con una puerta de enlace compatible con la dirección IP del CPE. 
                        Actualiza la dirección IP WAN del CPE o selecciona otro router de administración`)
        }
      }

      // Actualización de los DNS sugeridos
      // Se obtiene el router seleccionado
      var router = self.routers.find(function(rt) {
        return rt.id == self.cpeSeleccionado.id_router
      })

      if (router == null) {
        self.configuracionCpe.dns1 = 'No hay DNS sugerido'
        self.configuracionCpe.dns2 = 'No hay DNS sugerido'
        self.configuracionCpe.cidr = null
      } else {
        self.configuracionCpe.dns1 = router.dns1_sugerido
        self.configuracionCpe.dns2 = router.dns2_sugerido
      }
    },

    cargarCliente: function() {
      var self = this
      let idCliente = self.$route.params.id_cliente

      ClienteSrv.clienteJSON(idCliente).then(response => {
        self.cliente = response.data

        if(self.cliente.tiene_foto != false) {
          self.foto = self.API + '/clientes/' + self.cliente.id + '/foto?foto=256x256&_tk=' + self.tk + '&rnd=' + Math.random()
          self.foto_visor = self.API + '/clientes/' + self.cliente.id + '/foto?foto=original&_tk=' + self.tk + '&rnd=' + Math.random()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el cliente' + idCliente
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    iniciarMapa: function() {
      var self = this

      SistemaSrv.posicionInicial().then(response => {
        let posicion = response.data

        self.posicionInicial = {
          lat: parseFloat(posicion.latitud),
          lng: parseFloat(posicion.longitud)
        }

        self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)

        if (self.modo == 'nuevo') {
          self.contrato.latitud = parseFloat(posicion.latitud)
          self.contrato.longitud = parseFloat(posicion.longitud)

          self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarPaises: function() {
      var self = this
      
      let params = {
        datos_solicitados: ['id','nombre','prefijo_telefonico']
      }

      SistemaSrv.paisesJSON(params).then(response => {
        self.paises = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los paises'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMarcasCpes: function(callback) {
      var self = this

      MarcaCpesSrv.marcasJSON().then(response => {
        self.marcas_cpes = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelosCpes: function(callback) {
      var self = this
      let params = { id_marca: self.cpeSeleccionado.id_marca }

      ModeloCpesSrv.modelosJSON(params).then(response => {
        self.modelos_cpes = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstadosCpes: function(callback) {
      var self = this
      EstadoCpesSrv.estadosJSON().then(response => {
        self.estados_cpes = response.data

        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los estados'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstadosDH: function(callback) {
      var self = this
      
      EstadoDhsSrv.estadosJSON().then(response => {
        self.estados_dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los estados'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMarcasDH: function(callback) {
      var self = this
      
      MarcaDhsSrv.marcasJSON().then(response => {
        self.marcas_dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelosDH: function(callback) {
      var self = this
      
      let params = { id_marca: self.dhSeleccionado.id_marca }

      ModeloDhsSrv.modelosJSON(params).then(response => {
        self.modelos_dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTiposDH: function() {
      var self = this

      TipoDhsSrv.tiposJSON().then(response => {
        self.tipos_dh = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los tipos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPrefijoTelefonico: function() {
      var self = this

      SistemaSrv.ajustes(['prefijo_telefonico']).then(response => {
        let ajustes = response.data
        self.prefijo = ajustes.prefijo_telefonico
      })
    },

    cargarFotoCliente: function() {
      var self = this

      SistemaSrv.ajustes(['foto_cliente']).then(response => {
        self.foto_cliente = response.data.foto_cliente == "0" ? false : true
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminarTelefono: function(index) {
      var self = this

      self.cliente.telefonos.splice(index, 1)
    },

    guardar: function() {
      var self = this
      let cliente = Object.assign({}, self.cliente)

      self.bandera_spinner = true

      if(cliente.clave_sin_encriptar != null && cliente.clave_sin_encriptar != ''){
        let clave_sin_encriptar = self.cliente.clave_sin_encriptar
        let clave_encriptada = md5(clave_sin_encriptar)

        self.cliente.clave = clave_encriptada
        self.cliente.clave_sin_encriptar = clave_sin_encriptar
        cliente.clave = clave_encriptada
        cliente.clave_sin_encriptar = clave_sin_encriptar
      }

      if(cliente.nombre == ''){
        iu.msg.warning('Es necesario un nombre para el cliente')
        self.$refs.nombre_cliente.select()
        self.bandera_spinner = false
        return
      }

      if(cliente.apellido_paterno == ''){
        iu.msg.warning('Es necesario un apellido paterno para el cliente')
        self.$refs.apellido_paterno.select()
        self.bandera_spinner = false
        return
      }

      // Validación de datos
      if(self.bandera_nuevo_contrato == true) {
        let contrato =  Object.assign({}, self.contrato)

        if (contrato.fecha_instalacion == '') {
          iu.msg.warning('Es necesaria una fecha de instalación')
          // self.$refs.fecha_instalacion.select()
          self.bandera_spinner = false
          return
        }

        if (contrato.inicio_periodo == '') {
          iu.msg.warning('Es necesaria una fecha de perido inicial')
          // self.$refs.inicio_periodo.select()
          self.bandera_spinner = false
          return
        }

        if (contrato.fin_periodo == '') {
          iu.msg.warning('Es necesaria una fecha de perido inicial')
          // self.$refs.fin_periodo.select()
          self.bandera_spinner = false
          return
        }

        if (contrato.id_cpe != null) {
          if (contrato.id_router == null) {
            iu.msg.warning('Es necesario seleccionar un router de administración')
            self.bandera_spinner = false
            return
          }

          if (contrato.id_isp == null) {
            iu.msg.warning('Es necesario seleccionar un ISP')
            self.bandera_spinner = false
            return
          }
        }

        if (contrato.id_servicio == null) {
          iu.msg.warning('Es necesario seleccionar un servicio')
          self.$refs.id_servicio.select()
          self.bandera_spinner = false
          return
        }

        contrato.fecha_instalacion = contrato.fecha_instalacion != null ? moment(contrato.fecha_instalacion, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
        contrato.fecha_vencimiento = contrato.fecha_vencimiento != null ? moment(contrato.fecha_vencimiento, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
        contrato.inicio_periodo = contrato.inicio_periodo != null ? moment(contrato.inicio_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
        contrato.fin_periodo = contrato.fin_periodo != null ? moment(contrato.fin_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''

        self.guardarCPE(contrato)
      } else {
        ClienteSrv.guardar(cliente).then(response => {
          let idCliente = response.data
          iu.msg.success('Cliente guardado correctamente')
          self.$router.go(-1)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar el cliente'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(fin => {
          self.bandera_spinner = false
        })
      }
    },

    guardarCliente: function(){
      var self = this

      self.bandera_spinner = true

      if(self.cliente.id == null) {
        ClienteSrv.guardar(self.cliente).then(response => {
          let idCliente = response.data
          iu.msg.success('Cliente guardado correctamente')
          self.cliente.id = idCliente
          self.contrato.id_cliente = idCliente

          self.guardarContrato()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar el cliente'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(fin => {
          self.bandera_spinner = false
        })
      } else {
        self.guardarContrato()
        self.bandera_spinner = false
      }
    },

    guardarCPE: function(contrato) {
      var self = this,
          cpe = Object.assign({}, self.cpeSeleccionado)
      
      self.bandera_spinner = true

      if(contrato.id_cpe == null) {
        self.guardarDH(contrato)
        self.bandera_spinner = false
        return
      }

      if (cpe.pppoe == true) {
        if (cpe.usuario_pppoe == null || cpe.usuario_pppoe == '') {
          iu.msg.warning('Es necesario un usuario para la credencial PPPoE')
          self.$refs.usuario_pppoe.select()
          self.bandera_spinner = false
          return
        }
        if (cpe.clave_pppoe == null || cpe.clave_pppoe == '') {
          iu.msg.warning('Es necesario una clave para la credencial PPPoE')
          self.$refs.clave_pppoe.select()
          self.bandera_spinner = false
          return
        }
      }

      if (!self.datosCorrectos(cpe)) {
        self.bandera_spinner = false
        return
      }

      cpe.control_manual = self.contrato.control_manual_cpe
      cpe.enlace_activo = self.contrato.enlace_activo_cpe

      if(contrato.id_cpe == -1) {
        CpeSrv.guardar(cpe).then(response => {
          iu.msg.success('Nuevo CPE guardado')
          self.contrato.id_cpe = response.data
          self.cpesDisponiblesParaContrato()
          self.guardarDH(contrato)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar'
          }
          iu.msg.error(mensaje)
          console.log(error.response.data)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }

      if(contrato.id_cpe != null && contrato.id_cpe != -1 && self.actualizarCpe == true) {
        CpeSrv.actualizar(cpe).then(response => {
          iu.msg.success('CPE actualizado correctamente')
          self.guardarDH(contrato)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo actualizar'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }

      if(contrato.id_cpe != null && contrato.id_cpe != -1 && self.actualizarCpe != true) {
        self.guardarDH(contrato)
        self.bandera_spinner = false
        return
      }
    },

    guardarDH: function(contrato) {
      var self = this
      var dh = Object.assign({}, self.dhSeleccionado)

      self.bandera_spinner = true

      if(contrato.id_dh == null) {
        self.guardarCliente()
        self.bandera_spinner = false
        return
      }

      if(contrato.id_dh == -1){
        DhSrv.guardar(dh).then(response => {
          iu.msg.success('Nuevo DH guardado')
          // Limpieza del formulario
          self.contrato.id_dh = response.data
          self.dhsDisponiblesParaContrato()
          self.guardarCliente()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch(e) {
            mensaje = 'No se pudo guardar el nuevo DH'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }

      if(contrato.id_dh != null && contrato.id_dh != -1 && self.actualizarDh == true){
        DhSrv.actualizar(dh).then(response => {
          iu.msg.success('DH actualizado correctamente')
          self.guardarCliente()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch(e) {
            mensaje = 'No se pudo actualizar'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }

      if(contrato.id_dh != null && contrato.id_dh != -1 && self.actualizarDh != true){
        self.guardarCliente()
        self.bandera_spinner = false
        return
      }
    },

    guardarContrato: function() {
      var self = this,
          contrato = Object.assign({}, this.contrato)

      contrato.fecha_instalacion = contrato.fecha_instalacion != null ? moment(contrato.fecha_instalacion, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.fecha_vencimiento = contrato.fecha_vencimiento != null ? moment(contrato.fecha_vencimiento, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.inicio_periodo = contrato.inicio_periodo != null ? moment(contrato.inicio_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.fin_periodo = contrato.fin_periodo != null ? moment(contrato.fin_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''

      self.bandera_spinner = true

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      ContratoSrv.guardar(contrato).then(response => {
        let idContrato = response.data
        iu.msg.success('Contrato guardado correctamente')

        swal.fire({
          title: "Generación de factura",
          html: '¿Desea generar la primera factura para el contrato registrado?',
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            ContratoSrv.generarFactura(idContrato).then(response => {
              swal.fire("Generado!", "Se creó correctamente la factura", "success")
              self.atras()
            }).catch(error => {
              let mensaje
              try {
                mensaje = error.response.data.message
              } catch (e) {
                mensaje = 'No se pudo crear la factura'
              }
              iu.msg.error(mensaje)
              console.log(error)
              self.atras()
            })
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            self.atras()
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    datosCorrectos: function(cpe) {
      var self = this

      if (cpe.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return false
      }

      if (cpe.id_marca == null) {
        iu.msg.warning('Es necesario seleccionar una marca')
        self.$refs.id_marca.select()
        self.bandera_spinner = false
        return false
      }

      if (cpe.id_modelo == null) {
        iu.msg.warning('Es necesario seleccionar un modelo')
        self.$refs.id_modelo.select()
        self.bandera_spinner = false
        return false
      }

      if (cpe.pppoe == false && cpe.ip_wan == '') {
        iu.msg.warning('Es necesario registrar una IP WAN válida')
        self.$refs.ip_wan.select()
        self.bandera_spinner = false
        return false
      }

      if(cpe.pppoe == false && cpe.ip_wan.length > 15){
        iu.msg.warning('La direccion IP del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion IP')
        self.$refs.ip_wan.select()
        self.bandera_spinner = false
        return false
      }

      if(cpe.ip_lan != '' && cpe.ip_lan != null && cpe.ip_lan.length > 15){
        iu.msg.warning('La direccion IP del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion IP')
        self.$refs.ip_lan.select()
        self.bandera_spinner = false
        return false
      }

      if(cpe.mac_wan != '' && cpe.mac_wan != null) {
        if(cpe.mac_wan.length > 17){
          iu.msg.warning('La direccion MAC del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion MAC')
          self.$refs.mac_wan.select()
          self.bandera_spinner = false
          return false
        }
      }

      if(cpe.mac_lan != '' && cpe.mac_lan != null) {
        if(cpe.mac_lan.length > 17){
          iu.msg.warning('La dirección MAC del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion MAC')
          self.$refs.mac_lan.select()
          self.bandera_spinner = false
          return false
        }
      }

      try {
        if (
          cpe.ip_wan != '' &&
          cpe.ip_lan != '' &&
          self.configuracionCpe.cidr != null &&
          self.configuracionCpe.cidr != ''
        ) {
          var net1 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_wan + '/' + self.configuracionCpe.cidr
            ),
            net2 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_lan + '/' + self.configuracionCpe.cidr
            )

          if (net1.toString() == net2.toString()) {
            iu.msg.error( 'El segmento de red de la dirección IP LAN debe ser diferente al segmento de red de la dirección IP WAN' )
            self.$refs.ip_lan.select()
            self.bandera_spinner = false
            return false
          }
        } else {
          var net1 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_wan + '/24'
            ),
            net2 = ipaddr.IPv4.networkAddressFromCIDR(self.cpe.ip_lan + '/24')

          if (net1.toString() == net2.toString()) {
            iu.msg.warning( 'Es muy probable que la dirección IP WAN y LAN estén en el mismo segmento. Revisa que los datos sean correctos.' )
            self.$refs.ip_lan.select()
            self.bandera_spinner = false
          }
        }
      } catch (error) {}

      if (cpe.id_router != null) {
        if (cpe.id_isp == null) {
          iu.msg.warning('Es necesario seleccionar un proveedor de internet')
          self.$refs.id_isp.select()
          self.bandera_spinner = false
          return false
        }

        if (cpe.id_perfil_velocidad == null) {
          iu.msg.warning('Es necesario seleccionar un perfil de velocidad')
          // self.$refs.id_perfil_velocidad.select()
          self.bandera_spinner = false
          return false
        }
      }

      return true
    },

    refrescarTelefonos: function() {
      var self = this

      ClienteSrv.telefonosCliente(self.idCliente).then(response => {
        self.cliente.telefonos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los telefonos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    mostrarPosicion: function(event) {
      var self = this 

      self.contrato.latitud = event.latLng.lat()
      self.contrato.longitud = event.latLng.lng()

      self.posicionInicial = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      self.lat_lng = event.latLng.lat()+','+event.latLng.lng()
    },

    actualizarPosicion: function() {
      var self = this, array = self.lat_lng.split(',')

      if(array.length == 1 || array.length > 2) {
        iu.msg.warning("No es una coordenada válida, ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.contrato.latitud)+','+parseFloat(self.contrato.longitud)
        return
      }
      let latitud = parseFloat(array[0]), longitud = parseFloat(array[1])

      if(!latitud || !longitud) {
        iu.msg.warning("Latitud o longitud no son coordenadas válidas; ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.contrato.latitud)+','+parseFloat(self.contrato.longitud)
        return
      }

      self.contrato.latitud = latitud
      self.contrato.longitud = longitud

      self.posicionInicial = {
        lat: latitud,
        lng: longitud
      }

      self.lat_lng = latitud+','+longitud
    },

    seleccionarFoto: function() {
      let self = this
      var inpFoto = document.createElement("input")
      inpFoto.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFoto.click()
      
      reader.onload = function(e) {
        self.foto = this.result
        self.foto_visor = this.result
      }

      inpFoto.addEventListener('change', function(){
        self.cliente.foto = inpFoto.files[0]
        self.cliente.eliminar_antigua_foto = true
        reader.readAsDataURL(self.cliente.foto)
      })
    },

    usarDireccionCliente: function() {
      var self = this

      self.contrato.calle = self.cliente.calle
      self.contrato.codigo_postal = self.cliente.codigo_postal
      self.contrato.colonia = self.cliente.colonia
      self.contrato.estado_residencia = self.cliente.estado_residencia
      self.contrato.municipio = self.cliente.municipio
      self.contrato.numero_exterior = self.cliente.numero_exterior
      self.contrato.numero_interior = self.cliente.numero_interior
      self.contrato.pais = self.cliente.pais
    },

    generarClave: function() {
      var self = this, password = '',
          chars = "abcdefghijklmnopqrstubwsyz1234567890@-+<>()=[]{}"

      for(let i=0; i< 9; i++){
        password+=chars.charAt(Math.floor(Math.random()*chars.length));
      }

      self.cliente.clave_sin_encriptar = password

      let nombre_cliente = self.cliente.nombre +
        (self.cliente.apellido_paterno ? ' '+self.cliente.apellido_paterno : '') +
        (self.cliente.apellido_materno ? ' '+self.cliente.apellido_materno : ''),
        mensaje = "Se ha generado correctamente una nueva clave de acceso para el cliente "+nombre_cliente

      if(self.modo != "nuevo") mensaje += ". <br><br> Es necesario actualizar el cliente para poder almacenar la nueva clave de acceso."
      iu.msg.question(mensaje, { width: 500 })
    },

    copiarClave: function() {
      var self = this

      var aux = document.createElement("input");
      aux.setAttribute("value", self.cliente.clave_sin_encriptar);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);

      let nombre_cliente = self.cliente.nombre +
        (self.cliente.apellido_paterno ? ' '+self.cliente.apellido_paterno : '') +
        (self.cliente.apellido_materno ? ' '+self.cliente.apellido_materno : '')

      iu.msg.success("La clave del cliente "+nombre_cliente+", se a copiado correctamente")
    },

    copiarNumeroCliente: function() {
      var self = this,
      valor = self.cliente.prefnumcli+"-"+self.cliente.numero

      var aux = document.createElement("input");
      aux.setAttribute("value", valor);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);

      iu.msg.success("El numero de cliente <strong>"+valor+"</strong>, se a copiado correctamente", { width: 400 })
    }
  }
}